<template>
  <div>
    <div
      class="navbar-layout"
      :class="{ 'open-mobile': isMobileOpen }"
      @click="hideMobile"
    ></div>
    <header class="header" :class="{ 'open-mobile': isMobileOpen }">
      <div class="header__inner">
        <div class="header__left-content">
          <router-link :to="{ name: 'Home' }" class="header-logo">
            <img :src="require('@/assets/img/logo-dark.svg')" alt="" />
          </router-link>
          <div class="menu-wrapper">
            <ul class="menu">
              <li>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="menu__item"
                >
                  {{ $t("menu.platform") }}
                </router-link>
              </li>
              <li
                class="has-submenu"
                :class="{ 'submenu-mobile_open': isSubmenuOpen === 1 }"
              >
                <div class="submenu-mobile-link">
                  <router-link
                    :to="$localePath({ name: 'Markets' })"
                    class="menu__item"
                  >
                    {{ $t("menu.markets") }}
                  </router-link>
                  <div class="submenu-btn" @click="toggleSubmenu(1)">
                    <span class="icon-arrow-down"></span>
                  </div>
                </div>
                <ul class="submenu">
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Stock' })"
                      class="submenu__item"
                    >
                      {{ $t("subMenu.stock") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Forex' })"
                      class="submenu__item"
                    >
                      {{ $t("subMenu.forex") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Indices' })"
                      class="submenu__item"
                    >
                      {{ $t("subMenu.indices") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Commodities' })"
                      class="submenu__item"
                    >
                      {{ $t("subMenu.commodities") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Currency' })"
                      class="submenu__item"
                    >
                      {{ $t("subMenu.currency") }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="has-submenu"
                :class="{ 'submenu-mobile_open': isSubmenuOpen === 2 }"
              >
                <div class="submenu-mobile-link">
                  <div class="menu__item">
                    {{ $t("company") }}
                  </div>
                  <div class="submenu-btn" @click="toggleSubmenu(2)">
                    <span class="icon-arrow-down"></span>
                  </div>
                </div>
                <ul class="submenu">
                  <li>
                    <router-link
                      :to="$localePath({ name: 'About' })"
                      class="submenu__item"
                    >
                      {{ $t("menu.about") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="$localePath({ name: 'Safety' })"
                      class="submenu__item"
                    >
                      {{ $t("menu.safe") }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="menu__item"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </li>
            </ul>
            <div class="menu-btns">
              <router-link
                :to="$localePath({ name: 'Registration' })"
                class="btn btn-md btn-gray-outline black-text"
              >
                <span class="btn-inner">
                  {{ $t("btn.registration") }}
                </span>
              </router-link>
              <router-link
                :to="$localePath({ name: 'Login' })"
                class="btn btn-md btn-primary-2"
              >
                <span class="btn-inner">
                  {{ $t("btn.login") }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="header__right-content">
          <LanguageSwitcher />
          <div class="header-btns">
            <router-link
              :to="$localePath({ name: 'Registration' })"
              class="btn btn-md btn-gray-outline black-text"
            >
              <span class="btn-inner">
                {{ $t("btn.registration") }}
              </span>
            </router-link>
            <router-link
              :to="$localePath({ name: 'Login' })"
              class="btn btn-md btn-primary-2"
            >
              <span class="btn-inner">
                {{ $t("btn.login") }}
              </span>
            </router-link>
          </div>

          <div class="hamburger-lines" @click="toggleMobile">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/app/LanguageSwitcher.vue";

export default {
  name: "AppHeader",
  components: { LanguageSwitcher },
  data: function () {
    return {
      isFixed: false,
      isMobileOpen: false,
      isSubmenuOpen: false,
    };
  },
  mounted() {
    const body = window.document.body;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 120) {
        this.isFixed = true;
        body.classList.add("is-header-fixed");
      } else {
        this.isFixed = false;
        body.classList.remove("is-header-fixed");
      }
    });
  },
  watch: {
    $route() {
      this.isMobileOpen = false;
      this.isSubmenuOpen = false;
    },
  },
  methods: {
    toggleMobile() {
      this.isMobileOpen = !this.isMobileOpen;
    },
    toggleSubmenu(id) {
      if (this.isSubmenuOpen === id) {
        this.isSubmenuOpen = false;
      } else {
        this.isSubmenuOpen = id;
      }
    },
    hideMobile() {
      this.isMobileOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  background: $white;
  box-shadow: $box-shadow1;
  z-index: 10;

  .is-header-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.header-logo {
  height: 40px;
  img {
    height: 40px;
  }
}
.header__inner {
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__left-content {
  display: flex;
  align-items: center;
  gap: 60px;
}

.header__right-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-wrapper {
  position: relative;
}

.menu {
  display: flex;
  align-items: center;
  gap: 60px;

  li {
    position: relative;

    &.has-submenu {
      &:hover {
        .submenu {
          opacity: 1;
          transform: rotateX(0);
        }
      }
    }
  }
}

.menu__item {
  font-size: 16px;
  color: $dark;
  font-family: "sansationregular";

  &:hover {
    color: $primary-color;
  }
}

.submenu-btn {
  display: none;
}

.menu-btns {
  display: none;
  margin-top: 30px;

  .btn {
    width: 100%;
  }

  .btn + .btn {
    margin-top: 10px;
  }
}

.submenu {
  min-width: 210px;
  opacity: 0;
  position: absolute;
  padding: 6px 30px;
  background: $white;
  top: calc(100% + 21px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: all ease-in-out 0.3s;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  border-radius: 0 0 10px 10px;

  li {
    padding: 8px 0;

    &:last-child {
      border-bottom: none;
    }
  }

  .submenu__item {
    color: $dark;
    font-size: 16px;
    line-height: 20px;

    &:hover {
      color: $primary-color;
    }
  }
}
.submenu-mobile-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: $transition;

  &:hover {
    .menu__item,
    .submenu-btn {
      color: $primary-color;
    }
  }
}
.submenu-btn {
  display: block;
  padding: 10px;
  font-size: 14px;
  color: $dark;
  line-height: 1;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.navbar-layout {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($dark, 0.2);
  z-index: 1;

  &.open-mobile {
    display: block;
  }
}

.hamburger-lines {
  position: relative;
  display: none;
  height: 24px;
  width: 32px;
  cursor: pointer;
}

.hamburger-lines .line {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  background: $primary-color;
  left: 0;
}

.hamburger-lines .line1 {
  top: 0;
  transform-origin: 0% 0%;
  transition: transform 0.3s ease-in-out;
}

.hamburger-lines .line2 {
  top: calc(12px - 1px);
  transition: transform 0.1s ease-in-out;
}

.hamburger-lines .line3 {
  bottom: 0;
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
}

.open-mobile .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.open-mobile .hamburger-lines .line2 {
  transform: scaleY(0);
}

.open-mobile .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

@include media-breakpoint-down(xxl) {
  .header__inner {
    padding: 20px 30px;
  }
  .menu {
    gap: 30px;
  }
}

@include media-breakpoint-down(xl) {
  .header__left-content {
    gap: 40px;
  }
}

@include media-breakpoint-down(xl) {
  .header-btns {
    display: none;
  }
  .menu-btns {
    display: block;
  }
  .hamburger-lines {
    display: block;
  }
  .header__inner {
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 30px;
  }
  .header__right-content {
    gap: 15px;
  }
  .menu-wrapper {
    position: fixed;
    top: 61px;
    right: 0;
    bottom: 0;
    width: 300px;
    overflow-y: auto;
    padding: 30px 20px;
    background: $white;
    transition: all ease-in-out 0.3s;
    transform: translateX(110%);

    .menu {
      flex-direction: column;
      align-items: start;
      gap: 20px;

      li {
        width: 100%;
      }
    }
  }

  .open-mobile {
    .menu-wrapper {
      transform: translateX(0);
    }
  }

  .submenu {
    display: none;
    position: relative;
    opacity: 1;
    transform: none;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 5px 20px;

    li {
      padding: 10px 0;
    }

    .submenu__item {
      color: $dark;

      &:hover {
        color: $primary-color;
      }
    }
  }
  .submenu-mobile_open {
    .submenu-btn {
      transform: rotate(180deg);
    }

    .submenu {
      display: block;
    }
  }
}
</style>
